
import api from "@/api";
import {
  OrganisationCreateModel,
  OrganisationModel,
  VenueModel,
} from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CreateOrganisationDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  private orgnanisationId = "";
  private loading = false;
  private organisations: Array<OrganisationModel> = [];
  private venues: Array<VenueModel> = [];
  private model: OrganisationCreateModel = {
    name: "",
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async created() {
    await this.getVenues();
  }

  private async getVenues() {
    try {
      const response = await api.VenueService.apiVenueGet();
      this.venues = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Venues");
    }
  }

  private async onOrganisationCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.OrganisationService.apiOrganisationPost(this.model);
      snackbarModule.setSnackbarMessage("Organisation created");
      this.$emit("created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create organisation");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
