
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import {
  OrganisationUpdateModel,
  ConstantModel,
  VenueModel,
} from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class UpdateOrganisationDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) organisationId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private userRoles: Array<ConstantModel> = [];
  private venues: Array<VenueModel> = [];
  private model: OrganisationUpdateModel = {
    name: "",
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const response =
      await api.OrganisationService.apiOrganisationOrganisationIdGet(
        this.organisationId
      );
    this.model = response.data;
  }

  private async created() {
    this.getOrganisationById();
    await this.getVenues();
  }

  private async getVenues() {
    try {
      const response = await api.VenueService.apiVenueGet();
      this.venues = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Venues");
    }
  }

  private async getOrganisationById() {
    const response =
      await api.OrganisationService.apiOrganisationOrganisationIdGet(
        this.organisationId
      );
    this.model = response.data;
  }

  private async onUserUpdate() {
    if (!this.form?.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.OrganisationService.apiOrganisationOrganisationIdPut(
        this.organisationId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Organisation updated");
      this.$emit("updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update organisation");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
