
import { Vue, Component } from "vue-property-decorator";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import OrganisationTable from "@/components/organisation/OrganisationTable.vue";
import CreateOrganisationDialog from "@/components/dialogs/organisation/CreateOrganisationDialog.vue";
import UpdateOrganisationDialog from "@/components/dialogs/organisation/UpdateOrganisationDialog.vue";
import { OrganisationModel } from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    OrganisationTable,
    CreateOrganisationDialog,
    UpdateOrganisationDialog,
  },
})
export default class OrganisationOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private deleteDialog = false;
  private selectedOrganisationId = "";

  private async onDeleteRequest(organisation: OrganisationModel) {
    if (!organisation.organisationId) {
      return;
    }
    this.selectedOrganisationId = organisation.organisationId;
    this.deleteDialog = true;
  }

  private async onDeleteOrganisation() {
    if (!this.selectedOrganisationId) {
      return;
    }
    try {
      await api.OrganisationService.apiOrganisationOrganisationIdDelete(
        this.selectedOrganisationId
      );
      snackbarModule.setSnackbarMessage(`Organisation successfully deleted`);
      this.tableRefreshKey = !this.tableRefreshKey;
      this.$emit("deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete organisation");
    }
  }

  private onUpdateOrganisation(item: OrganisationModel) {
    if (!item.organisationId) {
      return;
    }

    this.selectedOrganisationId = item.organisationId;
    this.updateDialog = true;
  }
}
